<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_package')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.PackageName"
                            :label="$t('package_name')"
                                  dense
                                  outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.PackageType"
                            :label="$t('package_type')"
                            :error-messages="PackageTypeErrors"
                            required
                                  dense
                                  outlined
                            @input="$v.editedData.PackageType.$touch()"
                            @blur="$v.editedData.PackageType.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.PackageRate"
                            :label="$t('package_rate')"
                                  dense
                                  outlined
                            :error-messages="PackageRateErrors"
                            required
                            type="number"
                            @input="$v.editedData.PackageRate.$touch()"
                            @blur="$v.editedData.PackageRate.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.PackageDescription"
                                  dense
                                  outlined
                            :label="$t('package_description')"
                    ></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success"],
        computed: {

            PackageNameErrors() {
                const errors = [];
                if (!this.$v.editedData.PackageName.$dirty) return errors;
                !this.$v.editedData.PackageName.required &&
                errors.push("Package Name is required.");
                return errors;
            },
            PackageTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.PackageType.$dirty) return errors;
                !this.$v.editedData.PackageType.required &&
                errors.push("Package Type is required.");
                return errors;
            },
            PackageRateErrors() {
                const errors = [];
                if (!this.$v.editedData.PackageRate.$dirty) return errors;
                !this.$v.editedData.PackageRate.required &&
                errors.push("Package Rate is required.");
                return errors;
            }
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {
                    PackageName: "",
                    PackageType: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                PackageName: {required},
                PackageType: {required},
                PackageRate: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        PackageName: this.editedData.PackageName,
                        PackageType: this.editedData.PackageType,
                        PackageDescription: this.editedData.PackageDescription,
                        PackageRate: parseInt(this.editedData.PackageRate),
                    };
                    axios.post("Adventure/InsertIntoAdventurePackageAsync", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Package added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>